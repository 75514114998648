<script setup>
import OrganizationTopBar from './OrganizationTopbar.vue';
import OrganizationService from '@/service/OrganizationService';
import { useRoute } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import { ref, onBeforeMount, watch } from 'vue';
import * as _ from 'lodash';

const toast = useToast();

const route = useRoute();
const organizationId = route.params.id;
const bookingParams = ref(null);

const dt = ref(null);
const customers = ref(null);
const totalElements = ref(null);
const searchFilter = ref(null);
searchFilter.value = '';
const loading = ref(null);
const filterBlacklisted = ref(null);
filterBlacklisted.value = false;

const organizationService = new OrganizationService();

const onPageChange = (event) => {
  bookingParams.value = event;
  searchCustomers();
};

const debouncedSearch = _.debounce(async () => {
  const size = dt.value?.rows ?? 10;
  const page = bookingParams.value ? bookingParams.value.page : 0;
  const filter = searchFilter.value;
  const blacklisted = filterBlacklisted.value;
  const response = await organizationService.searchCustomer(
    organizationId,
    blacklisted,
    filter,
    page,
    size
  );
  customers.value = response.content;
  totalElements.value = response.totalElements;

  loading.value = false;
}, 250);

const updateBlacklist = (customerId, blacklisted, name) => {
  loading.value = true;
  organizationService.updateCustomerBlacklist(
    organizationId,
    customerId,
    blacklisted
  );
  toast.add({
    severity: 'success',
    summary: 'Lista negra actualizada',
    detail: `El usuario ${name} ha sido ${
      blacklisted ? 'añadido a' : 'eliminado de'
    } la lista negra`,
    life: 3000,
  });
  searchCustomers();
};

const onSearchFilterChange = () => {
  loading.value = true;
  searchCustomers();
};

const searchCustomers = async () => {
  loading.value = true;
  debouncedSearch();
};
onBeforeMount(searchCustomers);
watch([filterBlacklisted, searchFilter], onSearchFilterChange);
</script>

<template>
  <Toast />
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <div class="containerClass">
          <OrganizationTopBar />
        </div>
        <DataTable
          :value="customers"
          :paginator="true"
          class="p-datatable-gridlines"
          dataKey="id"
          ref="dt"
          :rowHover="true"
          :loading="loading"
          responsiveLayout="scroll"
          :rows="10"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 20, 40]"
          currentPageReportTemplate="Mostrando del {first}ª al {last}ª de {totalRecords} clientes"
          :totalRecords="totalElements"
          @page="onPageChange($event)"
          :lazy="true"
        >
          <template #header>
            <div class="flex justify-content-between flex-column sm:flex-row">
              <span class="p-input-icon-left mb-2">
                <i class="pi pi-search" />
                <InputText
                  v-model="searchFilter"
                  placeholder="Keyword Search"
                  style="width: 100%"
                />
              </span>
              <div class="flex align-items-center">
                <Checkbox
                  id="blacklisted_check"
                  :binary="true"
                  v-model="filterBlacklisted"
                  class="mr-2"
                ></Checkbox>
                <label for="blacklisted_check">Mostrar solo lista negra</label>
              </div>
            </div>
          </template>
          <template #empty> No se han encontrado clientes. </template>
          <template #loading>
            Cargando datos de clientes. Por favor espere.
          </template>
          <Column field="blacklisted" header="Lista negra">
            <template #body="{ data }">
              <div class="flex justify-content-between align-items-center">
                <span>
                  {{ data.blacklisted ? 'Bloqueado' : 'No bloqueado' }}
                </span>
                <Button
                  v-if="data.blacklisted"
                  icon="pi pi-user-plus"
                  class="p-button-rounded"
                  aria-label="Añadir a la lista negra"
                  @click="
                    updateBlacklist(data.id, !data.blacklisted, data.firstname)
                  "
                />
                <Button
                  v-else
                  icon="pi pi-ban"
                  class="p-button-rounded p-button-danger"
                  aria-label="Quitar de la lista negra"
                  @click="
                    updateBlacklist(data.id, !data.blacklisted, data.firstname)
                  "
                />
              </div>
            </template>
          </Column>
          <Column field="firstname" header="Nombre" style="min-width: 12rem">
            <template #body="{ data }">
              {{ data.firstname }}
            </template>
          </Column>
          <Column header="Apellidos" style="min-width: 12rem">
            <template #body="{ data }">
              {{ data.lastname }}
            </template>
          </Column>
          <Column header="Email" style="min-width: 12rem">
            <template #body="{ data }">
              {{ data.email }}
            </template>
          </Column>
          <Column header="Teléfono" style="min-width: 10rem">
            <template #body="{ data }">
              {{ data.phone }}
            </template>
          </Column>
          <Column header="Prefijo telefónico">
            <template #body="{ data }">
              {{ data.phoneExtension }}
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
::v-deep(.p-datatable-frozen-tbody) {
  font-weight: bold;
}
::v-deep(.p-datatable-scrollable .p-frozen-column) {
  font-weight: bold;
}
</style>
